import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode} from '@shapeable/types';
import { CommodityGroup } from '@shapeable/collaborate-ore-types';
import { breakpoints } from '@shapeable/theme';
import { ContentNode, Loader, classNames, useView, EntityFilters } from '@shapeable/web';
import { TabSpec, Tabs } from 'components/elements/tabs';
import { ExplorerView } from '../elements/explorer-view';
import { CommodityGroupDepositList } from './commodity-group-deposit-list';

const cls = classNames('commodity-group-main-layout');


// -------- Types -------->

export type CommodityGroupView = 'overview' | 'deposits' | 'library';

export type CommodityGroupMainLayoutProps = Classable & HasChildren & { 
  entity?: CommodityGroup;
};

export const CommodityGroupMainLayoutDefaultProps: Omit<CommodityGroupMainLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    font-size: 1em;
  `,
});

const SectionStyles = breakpoints({
  base: css`
  `,
});

const IntroStyles = breakpoints({
  base: css`
    
  `,
});

const TabsStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const LoaderStyles = breakpoints({
  base: css`
    
  `,
});

const DepositsStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
  `,
});

const FiltersStyles = breakpoints({
  base: css`
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`, 
    Section: styled.section`${SectionStyles}`,
      Loader: styled(Loader)`${LoaderStyles}`,

      Deposits: styled(CommodityGroupDepositList)`${DepositsStyles}`,
  
      Intro: styled(ContentNode)`${IntroStyles}`,
      Tabs: styled(Tabs)`${TabsStyles}`,

      Filters: styled(EntityFilters)`${FiltersStyles}`,
};

export const CommodityGroupMainLayout: Shapeable.FC<CommodityGroupMainLayoutProps> = (props) => {
  const { className, children, entity } = props;
  const { description } = entity;
  const { activeView, setActiveView } = useView<CommodityGroupView>('commodity-group', 'overview');

  const tabs: TabSpec<CommodityGroupView>[] = [
    {
      id: 'overview',
      label: 'Overview',
    },
    {
      id: 'deposits',
      label: 'Deposits',
    },
    {
      id: 'library',
      label: 'Library',
    },
  ];


  return (
    <My.Container className={cls.name(className)}>
      <My.Tabs onTabClick={setActiveView} activeTab={activeView} tabs={tabs} variant='section' />
      <ExplorerView>
      {
        activeView === 'overview' && 
        <My.Section>
          <My.Intro entity={description as TContentNode} />
        </My.Section>
      }
      {
        activeView === 'deposits' && 
        <My.Section>
          <My.Deposits />
        </My.Section>
      }
      {children}
      </ExplorerView>
    </My.Container>
  )
};

CommodityGroupMainLayout.defaultProps = CommodityGroupMainLayoutDefaultProps;
CommodityGroupMainLayout.cls = cls;