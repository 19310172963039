import { graphql } from "gatsby";
import { createGatsbyPageComponent } from "@shapeable/web";
import { CommodityGroupLayout } from '../../components/entities/commodity-group-layout';
export default createGatsbyPageComponent('CommodityGroup', { layout: CommodityGroupLayout });

export const query = graphql`
  query CommodityGroupsQuery($id: ID!) {
    platform {
      commodityGroup(id: $id) {
        __typename _schema { label pluralLabel }
        description { text }
        openGraph { description { text(truncate: { words: 20 }) } image { url } } 
        id slug path name
        
      }
    }
  }
  `;

