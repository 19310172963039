import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, Entity } from '@shapeable/types';
import { Commodity, Deposit } from '@shapeable/collaborate-ore-types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames, useEntity, DottedLine } from '@shapeable/web';
import { DepositCard } from './deposit-card';
const cls = classNames('deposit-card-list');

// -------- Types -------->

export type DepositCardListProps = Classable & HasChildren & { 
  items: Deposit[];
};

export const DepositCardListDefaultProps: DepositCardListProps = {
  items: []
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  `,
});

const ItemStyles = breakpoints({
  base: css`
  
  `,
});


// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
  Deposit: styled(DepositCard)`${ItemStyles}`,
};

export const DepositCardList: Shapeable.FC<DepositCardListProps> = (props) => {
  const { className, children, items } = props;
  
  return (
    <My.Container className={cls.name(className)}>
    {
      items.map(deposit => {
        return (
          <>
          <My.Deposit key={deposit.id} entity={deposit} />
          <DottedLine gap={5}/>
          </>
        );
      })
    }
    {children}
    </My.Container>
  )
};

DepositCardList.defaultProps = DepositCardListDefaultProps;
DepositCardList.cls = cls;